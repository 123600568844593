
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { Setting } from "@/core/model/setting";
import MutualInfo from "@/views/mutuales/MutualInfo.vue";
import { setCurrentPageActions } from "@/core/helpers/toolbar";

interface ItemSetting {
  uuid: string;
  value: string;
}

export default defineComponent({
  name: "admin-mutual-settings",
  components: {
    MutualInfo,
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const settings = ref<Array<Setting>>([]);
    const formData = ref({});
    const rules = ref({});
    const formRef = ref<null | HTMLFormElement>(null);
    const loading = ref<boolean>(false);

    const prepareSendData = () => {
      const sendData = {
        mutual: route.params.uuid,
        settings: [] as ItemSetting[],
      };
      let ind = 0;
      for (let key in formData.value) {
        sendData.settings[ind] = {
          uuid: key.substring(8).replace(new RegExp("_", "g"), "-"),
          value: formData.value[key],
        };
        ind++;
      }
      return sendData;
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          store
            .dispatch(Actions.ADMIN_MUTUAL_SAVE_SETTINGS, prepareSendData())
            .then(() => {
              loading.value = false;
              if (store.state.AdminMutualesModule.success) {
                Swal.fire({
                  text: "Ajustes actualizados con éxito",
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: "Continuar",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                });
              }
            })
            .catch(() => {
              let message = "";
              if (typeof store.state.AdminMutualesModule.errors === "object") {
                message = Object.values(store.state.AdminMutualesModule.errors).join(
                  "<br>"
                );
              } else if (Array.isArray(store.state.AdminMutualesModule.errors)) {
                message = store.state.AdminMutualesModule.errors.join("<br>");
              } else {
                message = store.state.AdminMutualesModule.errors;
              }
              loading.value = false;
              Swal.fire({
                text: message,
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Intenta de nuevo!",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
            });
        } else {
          Swal.fire({
            text: "Lo sentimos, parece que se han detectado algunos errores. Vuelve a intentarlo.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Continuar",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          return false;
        }
      });
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("Administrar Mutual", ["Mutuales", "Ajustes"]);
      setCurrentPageActions(false, false, []);
      loadData();
    });

    const generateRules = (settings) => {
      let ruleRequired;
      settings.forEach((item) => {
        if (item.required) {
          ruleRequired = {
            required: true,
            message: "Este campo es obligatorio",
            trigger: "blur",
          };
        } else {
          ruleRequired = null;
        }
        if (ruleRequired) {
          rules.value[
            "setting_" + item.uuid.replace(new RegExp("-", "g"), "_")
          ] = [ruleRequired];
        }
      });
    };

    const generateFormData = (settings) => {
      settings.forEach((item) => {
        formData.value[
          "setting_" + item.uuid.replace(new RegExp("-", "g"), "_")
        ] = item.mutuals[0]?.value;
      });
    };

    const loadData = () => {
      loading.value = true;
      store
        .dispatch(Actions.ADMIN_MUTUAL_GET_SETTINGS, route.params.uuid)
        .then(() => {
          settings.value = store.state.AdminMutualesModule.mutualSettings;
          generateFormData(settings.value);
          generateRules(settings.value);
          loading.value = false;
        });
    };

    return {
      submit,
      settings,
      formData,
      rules,
      formRef,
      loading,
    };
  },
});
